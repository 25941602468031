import { render, staticRenderFns } from "./SubProcNode.vue?vue&type=template&id=4841c4ce&scoped=true"
import script from "./SubProcNode.vue?vue&type=script&lang=js"
export * from "./SubProcNode.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4841c4ce",
  null
  
)

export default component.exports